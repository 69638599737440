import PropTypes from 'prop-types';

const Button = ({ children, ...buttonProps }) => {
  return (
    <button
      {...buttonProps}
      className={`btn relative border-black button bg-white text-black relative mx-auto focus:border-pink-400 active:bg-pink-400 hover:border-pink-300 focus:outline-none transition duration-150 ease-in-out ${buttonProps.className}`}
      >
      {children}
    </button>
  );
};

Button.propTypes = {
  title: PropTypes.string,
  children: PropTypes.string
};

export default Button;

import React, { useEffect, useState, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import Draggable from 'react-draggable';
import Countdown from 'react-countdown';
import Button from './components/Button';
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import p1 from "./assets/images/main.png";
import p2 from "./assets/images/prick-1.jpg";
import p3 from "./assets/images/prick-2.jpg";
import bp from "./assets/images/baby-prick.png";
import tp from "./assets/images/teenage-prick.png";
import p from "./assets/images/prick.png";
import q from "./assets/images/question.svg";
import frame from "./assets/images/frame.svg";
import twitter from "./assets/images/twitter.svg";
import instagram from "./assets/images/instagram.svg";
import discord from "./assets/images/discord.svg";
import contract from "./assets/images/contract.svg";
import lex from "./assets/images/crypto-lex.svg";
import can from "./assets/images/watering-can.png";
import rain from "./assets/images/rain/rain.svg";
import title from "./assets/images/mint-a-prick-blue.svg";

function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [feedback, setFeedback] = useState("Get ready to grab the watering can.");
  const [feedbackLink, setFeedbackLink] = useState("");
  const [claimingNft, setClaimingNft] = useState(false);
  const [able, setAble] = useState(true);
  const [stage, setStage] = useState('baby');
  const [growing, setGrowing] = useState(null);
  const [reset, setReset] = useState(null);
  const [tipStep, setTipStep] = useState(0);
  const waterCan = useRef();
  const waterBox = useRef();
  const rainRef = useRef();
  const mintRef = useRef();
  const feedbackRef = useRef();
  const [listening, setListening] = useState(false);
  const pricks = document.getElementsByClassName('prick');
  const live = false;

  const isTouchDevice = () => {
    return (('ontouchstart' in window) ||
     (navigator.maxTouchPoints > 0) ||
     (navigator.msMaxTouchPoints > 0));
  };

  const scrollTo = (element) => {
    window.scroll({
      behavior: 'smooth',
      left: 0,
      top: element.getBoundingClientRect().top +  window.scrollY
    });
}

  const claimNFTs = (_amount) => {
    if (!live) {
      if (isTouchDevice()) {
        scrollTo(document.getElementById('scroll-to'));
      }
      setFeedback("Contract isn't live yet - come back soon!");
      feedbackRef.current.classList.add('animate__shakeX');

      setTimeout(() => {
        feedbackRef.current.classList.remove('animate__shakeX');
      }, 1200);
      return;
    }
    if (blockchain.account === "" || blockchain.smartContract === null) {
      if (isTouchDevice()) {
        scrollTo(document.getElementById('scroll-to'));
      }
      document.getElementById('connect-btn').classList.add('animate__shakeX');
      return;
    }
    if (_amount <= 0) {
      return;
    }
    setFeedback("Minting you a Crypto Prick...");
    feedbackRef.current.classList.add('animate__pulse', 'animate__infinite');
    setClaimingNft(_amount);
    blockchain.smartContract.methods
      .mint(blockchain.account, _amount)
      .send({
        // gasLimit: "285000",
        // to: "0x827acb09a2dc20e39c9aad7f7190d9bc53534192",
        from: blockchain.account,
        value: blockchain.web3.utils.toWei((0.04 * _amount).toString(), "ether"),
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        feedbackRef.current.classList.remove('animate__infinite');
        feedbackRef.current.classList.add('animate__headShake');
        setClaimingNft(false);
      })
      .then((receipt) => {
        setFeedback("You now own a Prick.");
        setFeedbackLink("Go visit Opensea.io to view it.");
        feedbackRef.current.classList.remove('animate__infinite');
        feedbackRef.current.classList.add('animate__headShake');
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const onMouseMove = useCallback((e) => {
    waterBox.current.style.left = e.pageX - waterCan.current.width + 'px';
    waterBox.current.style.top = e.pageY - waterCan.current.height  + 'px';
  }, []);

  const grabCan = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!listening) {
      window.addEventListener('mousemove', onMouseMove, true);
      setListening(true);
    } else {
      releaseCan(e);
    }
  };

  const releaseCan = (e, tar) => {
    e.preventDefault();
    e.stopPropagation();
    window.removeEventListener('mousemove', onMouseMove, true);
    setListening(false);
    if (tar) {
      document.getElementsByClassName('react-draggable')[0].style = '';
    } else {
      waterBox.current.style.left = 'unset';
      waterBox.current.style.top = 'unset';
    }
    console.info('released');
  };

  const growCactus = (e, tar) => {
    e.preventDefault();
    e.stopPropagation();
    if (!listening && !tar) return;

    const clicked = tar || e.target.closest('.prick');
    const parent = clicked.closest('.parent');

    console.info('clicked', clicked)

    if (growing && growing !== clicked.dataset.id) {
      setStage(clicked.id.split('-')[0]);
      setGrowing(clicked.dataset.id);
      return setReset(e);
    }

    setGrowing(clicked.dataset.id);
    waterCan.current.style.transform = 'rotate(45deg)';
    rainRef.current.style.display = 'block';

    setTimeout(() => {

      waterCan.current.style.transform = 'rotate(0deg)';
      rainRef.current.style.display = 'none';

      if (stage === 'prick') {
        const top = isTouchDevice() ? 80 : 70;
        mintRef.current.style.top = parent.offsetTop - top + 'px';
        mintRef.current.style.left = parent.offsetLeft - ((153 - parent.offsetWidth) / 2) + 'px';
        mintRef.current.classList.add('animate__bounceIn');
        mintRef.current.classList.remove('hidden');
        releaseCan(e, tar);
      } else {
        const enter = document.getElementById(`${stage === 'teenage' ? 'prick' : 'teenage'}-${clicked.dataset.id}`);

        clicked.classList.add('animate__bounceOut');
        isTouchDevice() && clicked.nextSibling.classList.add('hidden');
        enter.classList.add('animate__bounceIn');
        setTimeout(() => {
          enter.classList.remove('hidden');
          isTouchDevice() && enter.nextSibling.classList.remove('hidden');
        }, 500);
        setStage(stage === 'teenage' ? 'prick' : 'teenage');
      }
      setTimeout(() => {
        setAble(true);
      });
    }, 1500);
  };

  const questionClicked = () => {
    if (isTouchDevice()) {
      scrollTo(document.getElementById('scroll-to'));
    }
    if (tipStep > 0) {
      if (live) {
        setFeedback("Want to mint more than 5? Click the contract icon to view on etherscan.");
      } else {
        setFeedback("Contract isn't live yet - come back soon!");
      }
    }

    setTipStep(1);
    feedbackRef.current.classList.add('animate__shakeX');

    setTimeout(() => {
      feedbackRef.current.classList.remove('animate__shakeX');
    }, 1200);
  };

  const doElsCollide = (el1, el2) => {
    const rect1 = el1.getBoundingClientRect();
    const rect2 = el2.getBoundingClientRect();

    return !(rect1.right < rect2.left ||
              rect1.left > rect2.right ||
              rect1.bottom < rect2.top ||
              rect1.top > rect2.bottom);
  };

  const handleOverlap = (e) => {
    var i = pricks.length;
    while (--i > -1) {
      if (doElsCollide(pricks[i].nextSibling, e.target.querySelector('.target'))) {
        setAble(false);
        growCactus(e, pricks[i]);
      } else {
        pricks[i].classList.remove("highlight");
      }
    }
  };

  useEffect(() => {
    if (!reset) return;
    growCactus(reset);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset]);

  useEffect(() => {
    getData();

    const drops = document.querySelectorAll('.raindrop');
    drops.forEach((drop) => {
      drop.style.setProperty('--d', (Math.random() * 2) - 1);
      drop.style.setProperty('--a', Math.random() + 0.5);
      drop.style.setProperty('--x', Math.floor(Math.random() * 100));
      drop.style.setProperty('--y', Math.floor(Math.random() * 30));
    });

    console.info('blockchain', blockchain)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blockchain.account]);

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <span>completed!</span>;
    } else {
      // Render a countdown
      return (
        <div className="flex flex-col items-center">
          <span className="font-bs-outline text-base">{days} days</span>
          <span>{hours}h:{minutes}m:{seconds}s</span>
        </div>
      );
    }
  };

  return (
    <section className="w-full">
      <div className="p-4 sm:p-8 ms:p-16 flex flex-col">
        <div className="w-full prick-screen flex flex-col ms:flex-row">
          <div className="w-3/5 main-box border-4 border-black background w-full h-full relative">
            <div className="parent w-10 absolute bottom-1/3 left-10 sm:left-16 ms:left-28" onClick={(e) => growCactus(e)}>
              <img alt="baby prick" data-id="0" id="baby-0" className="prick w-8/12 m-auto animate__animated" src={bp} />
              {isTouchDevice() && <div className="absolute top-0 right-1 left-1 h-4"></div>}
            </div>
            <div className="parent hidden sm:block w-14 absolute bottom-1/4 left-1/4" onClick={(e) => growCactus(e)}>
              <img alt="baby prick" data-id="1" id="baby-1" className="prick w-8/12 m-auto animate__animated" src={bp} />
              {isTouchDevice() && <div className="absolute top-0 right-1 left-1 h-4"></div>}
            </div>
            <div className="parent w-20 absolute bottom-3/20 sm:bottom-1/10 right-1/2" onClick={(e) => growCactus(e)}>
              <img alt="baby prick" data-id="2" id="baby-2" className="prick w-8/12 m-auto animate__animated" src={bp} />
              {isTouchDevice() &&  <div className="absolute top-0 right-1 left-1 h-4"></div>}
            </div>
            <div className="parent w-12 absolute bottom-3/10 right-1/3" onClick={(e) => growCactus(e)}>
              <img alt="baby prick" data-id="3" id="baby-3" className="prick w-8/12 m-auto animate__animated" src={bp} />
              {isTouchDevice() && <div className="absolute top-0 right-1 left-1 h-4"></div>}
            </div>
            <div className="parent w-16 absolute bottom-1/5 sm:bottom-3/20 right-10 sm:right-16 ms:right-28" onClick={(e) => growCactus(e)}>
              <img alt="baby prick" data-id="4" id="baby-4" className="prick w-8/12 m-auto animate__animated" src={bp} />
              {isTouchDevice() && <div className="absolute top-0 right-1 left-1 h-4"></div>}
            </div>
            {/*teenage pricks */}
            <div className="parent w-10 absolute bottom-1/3 left-10 sm:left-16 ms:left-28" onClick={(e) => growCactus(e)}>
              <img alt="teenage prick" data-id="0" id="teenage-0" className="prick w-10/12 m-auto animate__animated hidden" src={tp} />
              {isTouchDevice() && <div className="absolute top-0 right-1 left-1 h-4 hidden"></div>}
            </div>
            <div className="parent hidden sm:block w-14 absolute bottom-1/4 left-1/4" onClick={(e) => growCactus(e)}>
              <img alt="teenage prick" data-id="1" id="teenage-1" className="prick w-10/12 m-auto animate__animated hidden" src={tp} />
              {isTouchDevice() && <div className="absolute top-0 right-1 left-1 h-4 hidden"></div>}
            </div>
            <div className="parent w-20 absolute bottom-3/20 sm:bottom-1/10 right-1/2" onClick={(e) => growCactus(e)}>
              <img alt="teenage prick" data-id="2" id="teenage-2" className="prick w-10/12 m-auto animate__animated hidden" src={tp} />
              {isTouchDevice() && <div className="absolute top-0 right-1 left-1 h-4 hidden"></div>}
            </div>
            <div className="parent w-12 absolute bottom-3/10 right-1/3" onClick={(e) => growCactus(e)}>
              <img alt="teenage prick" data-id="3" id="teenage-3" className="prick w-10/12 m-auto animate__animated hidden" src={tp} />
              {isTouchDevice() && <div className="absolute top-0 right-1 left-1 h-4 hidden"></div>}
            </div>
            <div className="parent w-16 absolute bottom-1/5 sm:bottom-3/20 right-10 sm:right-16 ms:right-28" onClick={(e) => growCactus(e)}>
              <img alt="teenage prick" data-id="4" id="teenage-4" className="prick w-10/12 m-auto animate__animated hidden" src={tp} />
              {isTouchDevice() && <div className="absolute top-0 right-1 left-1 h-4 hidden"></div>}
            </div>
            {/*reg pricks */}
            <div className="parent w-10 absolute bottom-1/3 left-10 sm:left-16 ms:left-28" onClick={(e) => growCactus(e)}>
              <img alt="prick" data-id="0" id="prick-0" className="prick w-full animate__animated hidden" src={p} />
              {isTouchDevice() && <div className="absolute top-0 right-1 left-1 h-4 hidden"></div>}
            </div>
            <div className="parent hidden sm:block w-14 absolute bottom-1/4 left-1/4" onClick={(e) => growCactus(e)}>
              <img alt="prick" data-id="1" id="prick-1" className="prick w-full animate__animated hidden" src={p} />
              {isTouchDevice() && <div className="absolute top-0 right-1 left-1 h-4 hidden"></div>}
            </div>
            <div className="parent w-20 absolute bottom-3/20 sm:bottom-1/10 right-1/2" onClick={(e) => growCactus(e)}>
              <img alt="prick" data-id="2" id="prick-2" className="prick w-full animate__animated hidden" src={p} />
              {isTouchDevice() && <div className="absolute top-0 right-1 left-1 h-4 hidden"></div>}
            </div>
            <div className="parent w-12 absolute bottom-3/10 right-1/3" onClick={(e) => growCactus(e)}>
              <img alt="prick" data-id="3" id="prick-3" className="prick w-full animate__animated hidden" src={p} />
              {isTouchDevice() && <div className="absolute top-0 right-1 left-1 h-4 hidden"></div>}
            </div>
            <div className="parent w-16 absolute bottom-1/5 sm:bottom-3/20 right-10 sm:right-16 ms:right-28" onClick={(e) => growCactus(e)}>
              <img alt="prick" data-id="4" id="prick-4" className="prick w-full animate__animated hidden" src={p} />
              {isTouchDevice() && <div className="absolute top-0 right-1 left-1 h-4 hidden"></div>}
            </div>
            <div className="animate__animated absolute hidden" ref={mintRef}>
              <Button
                className="claim-btn"
                disabled={claimingNft ? 1 : 0}
                onClick={(e) => {
                  e.preventDefault();
                  claimNFTs(1);
                  getData();
                }}>
                {claimingNft === 1 ? "BUSY" : "Mint 1"}
              </Button>
              <Button
                className="claim-btn"
                disabled={claimingNft ? 1 : 0}
                onClick={(e) => {
                  e.preventDefault();
                  claimNFTs(5);
                  getData();
                }}>
                {claimingNft === 5 ? "BUSY" : "Mint 5"}
              </Button>
            </div>
            <div className="absolute bottom-0 left-0 sm:bottom-2 sm:left-2 h-10 sm:h-16 ms:h-20 m-0 flex justify-around border-4 border-black bg-white m-2">
              <div className="mx-4 mr-4 sm:mr-6 font-bs flex items-end no-wrap self-center">
                <span className="text-2xl sm:text-5xl ms:text-6xl">{data.totalSupply}</span>
                <span className="text-m flex no-wrap">/2500</span>
              </div>
              <img alt={"example"} src={p1} className="h-full-sf p-2 mr-1 sm:mr-2 opacity-0" />
              <img alt={"example"} src={p3} className="h-full pb-2 sm:pb-4 absolute top-1.5 right-1.5 sm:top-3 sm:right-3" />
              <img alt={"example"} src={p2} className="h-full pb-2 sm:pb-4 absolute top-1 right-1 sm:top-2 sm:right-2" />
              <img alt={"example"} src={p1} className="h-full pb-2 sm:pb-4 absolute top-0.5 right-0.5 sm:top-1 sm:right-1" />
            </div>
            <div className="info w-8 sm:w-9 ms:w-10 absolute top-2 right-2 flex flex-col justify-center item-center">
              <a href={blockchain.smartContract ? `https://rinkeby.etherscan.io/address/${blockchain.smartContract._address}` : 'https://rinkeby.etherscan.io'}>
                <img alt="contract" src={contract} className="animate__animated shake p-2" />
              </a>
              <img alt="question" src={q} className="animate__animated shake question-mark p-2" onClick={questionClicked} />
            </div>
          </div>

          <div className="controls ms:w-2/5 flex ms:flex-col flex-wrap item-center justify-between ms:h-full">
            <div className="first-row ms:w-full sm:w-1/2 flex ms:ml-3 mt-5 ms:mt-0" id="scroll-to">
              <div className="child px-2 bg-lime mr-2 flex flex-col justify-center items-center border-4 border-black" onClick={(e) => grabCan(e)}>
                {isTouchDevice() ? (
                  <Draggable onDrag={(e) => handleOverlap(e)} disabled={!able} ref={waterBox}>
                    <div className="m-auto" ref={waterBox}>
                      <img ref={waterCan} src={can} alt="watering can" width="80px" height="auto" className="can w-can m-auto h-full" />
                      <div className="target absolute top-6 right-0 w-3 h-3"></div>
                      <div className="rain absolute -right-1 -bottom-5 w-10 hidden" ref={rainRef}>
                        <img alt="rain drop" src={rain} width="8px" className="raindrop" />
                        <img alt="small drop" src={rain} width="6px" className="raindrop" />
                        <img alt="small drop" src={rain} width="5px" className="raindrop" />
                        <img alt="small drop" src={rain} width="8px" className="raindrop" />
                        <img alt="small drop" src={rain} width="4px" className="raindrop" />
                      </div>
                    </div>
                  </Draggable>
                ) : (
                  <div className="m-auto absolute can" ref={waterBox}>
                    <img ref={waterCan} src={can} alt="watering can" width="80px" height="auto" className="can w-can m-auto h-full" />
                    <div className="rain absolute -right-1 -bottom-5 w-10 hidden" ref={rainRef}>
                      <img alt="rain drop" src={rain} width="8px" className="raindrop" />
                      <img alt="small drop" src={rain} width="6px" className="raindrop" />
                      <img alt="small drop" src={rain} width="5px" className="raindrop" />
                      <img alt="small drop" src={rain} width="8px" className="raindrop" />
                      <img alt="small drop" src={rain} width="4px" className="raindrop" />
                    </div>
                  </div>
                )}
              </div>
              <div className="child px-0 flex justify-center">
                <img src={title} alt="mint a prick" className="m-auto h-full p-1 block" />
              </div>
            </div>
            {Number(data.totalSupply) === 2500 ? (
              <div className="relative bg-pink px-4 pt-6 pb-2 flex-grow sm:w-1/3 ms:w-full flex flex-col align-center justify-center border-4 border-black rounded-t ms:ml-3 ms:mt-3 mt-5">
                <div className="screen rounded-t-sm"></div>
                <h1 className="text-center mt-1">
                  The sale has ended.
                </h1>
                <p className="">
                  You can still find Crypto Pricks on{" "}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://opensea.io/collection/cryptopricks"
                  >
                    Opensea.io
                  </a>
                </p>
              </div>
            ) : (
            <>
              <div className="relative bg-pink px-4 pt-6 pb-2 flex-grow sm:w-1/3 ms:w-full flex flex-col items-center justify-center border-4 border-black rounded-t ms:ml-3 ms:mt-3 mt-5">
                <div className="screen rounded-t-sm"></div>
                <h1 className="text-center mt-1">
                  1 Prick costs 0.04 <span className="font-bs-outline text-base">ETHER</span>{' '}
                  <span className="text-xs">(excluding gas fee)</span>
                </h1>
                <p className="mt-2 text-center animate__animated" ref={feedbackRef}>
                  {feedback}
                  <span className="block">
                    <a href="https://testnets.opensea.io/" className="underline hover:text-gray-600">{feedbackLink}</a>
                  </span>
                </p>
              </div>
              <div className="relative bg-orange px-4 pt-6 pb-2 flex-grow w-full flex align-center justify-center border-4 border-black rounded-t ms:ml-3 mt-3">
                <div className="screen rounded-t-sm"></div>
                {(blockchain.account === "" ||
                blockchain.smartContract === null) && live ? (
                  <div className="flex flex-col align-center justify-center">
                    <p className="mt-1 text-center">
                      Connect to the Ethereum network
                    </p>
                    <Button
                      id="connect-btn"
                      className="animate__animated"
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(connect());
                        getData();
                      }}
                    >
                      CONNECT
                    </Button>
                    {blockchain.errorMsg !== "" ? (
                      <>
                        <p className="">
                          {blockchain.errorMsg}
                        </p>
                      </>
                    ) : null}
                  </div>
                ) : (
                  live ? (
                    <div className="mt-2 flex flex-col align-center justify-center">
                      You're successfully connected to
                      <p className="font-bs-outline text-base text-center">Ethereum Network</p>
                    </div>
                  ) : (
                    <div className="flex flex-col align-center justify-center">
                      <Countdown date={new Date(2022, 1, 14)} renderer={renderer} />
                    </div>
                  )
                )}
              </div>
              </>
            )}
          </div>

        </div>
        <div className="mt-4">
          <p className="text-xs bg-white inline">
            **Please make sure you are connected to the right network (Ethereum
            Mainnet) and the correct address. Once you make a
            purchase, you cannot undo!!
          </p>
        </div>
        <div className="w-full-full flex flex-col ms:flex-row">
          <div className="w-full h-96 ms:h-auto flex-auto ms:w-2/5 relative bg-pink px-4 pt-20 pb-16 flex justify-center border-4 border-black rounded-t ms:mt-3 mt-5 ms:-ml-3 ms:mr-3">
              <div className="screen rounded-t-sm"></div>
              <div className="relative w-full h-full flex items-center justify-center">
                <div className="gif-holder absolute w-64 h-64 px-8 box-content rounded-sm">
                </div>
                <div className="gif absolute w-64 h-64 px-8 box-content rounded-sm">
                </div>
                <img src={frame} alt="frame" className="absolute w-80 h-80" />
                <p>prick gallery</p>
              </div>
          </div>
          <div className="w-full ms:w-3/5 relative bg-blue px-4 ms:px-16 py-16 flex flex-col ms:flex-row justify-center border-4 border-black rounded-t ms:mt-3 mt-5">
              <div className="screen rounded-t-sm"></div>
              <div className="">
                <h1 className="text-left mt-2 font-bs text-xl">
                  About CryptoPricks
                </h1>
                <p className="py-4">
                  CryptoPricks is a collection of 2500 randomly generated NFTs on the ethereum network. These pricks don't just thrive in the desert of the blockchain - you've probably seen them lurking in your local discord channel or mansplaining in twitter threads. Careful - they're touchy. <br />
                  {/*<a
                    target="_blank"
                    className="underline"
                    rel="noopener noreferrer"
                    href="https://opensea.io/collection/crypto-pricks"
                  >
                    View on opensea
                  </a>*/}
                  <span className="flex mt-4">
                    <a href="https://twitter.com/crypto_pricks" target="_blank" rel="noopener noreferrer" className="mr-4">
                      <img src={twitter} alt="twitter" width="36px" />
                    </a>
                    <a href="https://discord.gg/tQjkAeePzb" target="_blank" rel="noopener noreferrer" className="mr-4">
                      <img src={discord} alt="discord" width="36px" />
                    </a>
                    <a href="https://instagram.com/cryptopricks" target="_blank" rel="noopener noreferrer" className="mr-4">
                      <img src={instagram} alt="instagram" width="36px" />
                    </a>
                  </span>
                </p>
                <h1 className="text-left mt-4 font-bs text-xl">
                  Team
                </h1>
                <div className="mt-4 flex flex-row">
                  <div className="w-56">
                    <img src={lex} alt="lex" className="lex" />
                    <p className="text-center p-2 font-bs-outline">lex</p>
                  </div>
                  <p className="pl-4 sm:pl-6 ms:pl-8">
                    Graphic artist and frontend developer always looking to learn more. Started exploring the world of NFTs and CryptoPricks is the result of that. One girl team, excited for what the future holds. Join the discord for details, voting and giveaways!!! :)
                  </p>
                </div>
              </div>
          </div>
        </div>
      </div>
      <div className="text-center p-3"><p className="bg-white inline p-2 rounded-full">© CryptoPricks NFT 2021. All rights reserved.</p></div>
    </section>
  );
}

export default App;
